import React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import screenshot from "../images/screenshot.png"
// import { rhythm } from "../utils/typography"

class BlogIndex extends React.Component<{ data: any; location; any }> {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title
    const posts = data.allMdx.edges

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="<index>" image={screenshot} />
        <div className="mb-16">
          <Bio />
        </div>
        {posts.map(({ node }) => {
          const title = node.frontmatter.title || node.fields.slug
          return (
            <article key={node.fields.slug}>
              <header className="mt-16">
                <span className="text-sm text-gray-500">
                  {node.fields.slug.split("/")[1]}
                </span>
                <h3 className="text-2xl font-black mb-2 font-header">
                  <Link
                    className="text-blue-600 shadow-none"
                    to={node.fields.slug}
                  >
                    {title}
                  </Link>
                </h3>
                <small>
                  {node.frontmatter.date}
                  {node.frontmatter.lastModified &&
                    node.frontmatter.date !== node.frontmatter.lastModified && (
                      <> (last updated {node.frontmatter.lastModified})</>
                    )}{" "}
                  - {node.fields.readingTime.text}
                </small>
              </header>
              <section>
                <p
                  className="mb-8"
                  dangerouslySetInnerHTML={{
                    __html: (node.frontmatter.description || node.excerpt)
                      .split("\n")
                      .join("<br/>"),
                  }}
                />
              </section>
            </article>
          )
        })}
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { fields: { isPublished: { eq: true }, isHidden: { ne: true } } }
      limit: 1000
    ) {
      edges {
        node {
          excerpt
          fields {
            slug
            readingTime {
              text
              time
              words
            }
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            lastModified(formatString: "MMMM DD, YYYY")
            title
            description
          }
        }
      }
    }
  }
`
