/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

// import { rhythm } from "../utils/typography"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          gatsbyImageData(layout: FIXED, width: 50, height: 50)
        }
      }
      site {
        siteMetadata {
          author
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author, social } = data.site.siteMetadata
  return (
    <div className="flex">
      <GatsbyImage
        className="mr-4 mb-0 rounded-full"
        image={data.avatar.childImageSharp.gatsbyImageData}
        alt={author}
        style={{
          minWidth: 50,
        }}
      />
      <p>
        <strong>Fred</strong> is a Nordic software engineer who once set out
        never to blog in his life, but then thought better of it.
        {` `}
        <a
          className="text-blue-600"
          target="_blank"
          href={`https://twitter.com/${social.twitter}`}
        >
          You should follow him on Twitter
        </a>
      </p>
    </div>
  )
}

export default Bio
